import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;
/** Base api */
/* 取得年度下拉選項 */
export const GetClassRoomYear = (Type = 99) => baseAxios.post('/Base/GetClassRoomYear', { Type });
/* 取得科目下拉選項 */
export const GetClassRoomSubject = (Type = 99) => baseAxios.post('/Base/GetClassRoomSubject', { Type });
/* 取得課堂中沒有講師的下拉選項 */
export const GetClassRoomUnTeacher = (data) => baseAxios.post('/Base/GetClassRoomUnTeacher', data);
/* 取得講師下拉選項 */
export const GetTeacher = (Type = 99) => baseAxios.post('/Base/GetTeacher', { Type });
/* 取得狀態下拉選項 */
export const GetStatus = (Type = 99) => baseAxios.post('/Base/GetStatus', { Type });

/* 取得是否顯示下拉選項 */
export const GetIsShow = (Type = 99) => baseAxios.post('/Base/GetIsShow', { Type });

/* 取得考試名稱下拉選項 */
export const GetBundleClassExam = (Type = 99) => baseAxios.post('/Base/GetBundleClassExam', { Type });
/* 取得考試類科的下拉選項 */
export const GetBundleClassCategory = (data) => baseAxios.post('/Base/GetBundleClassCategory', data);
/* 取得班別的下拉選項 */
export const GetBundleClassRoom = (data) => baseAxios.post('/Base/GetBundleClassRoom', data);

/* 取得最新消息分類下拉選項 */
export const GetNewsCategoryList = (Type = 99) => baseAxios.post('/Base/GetNewsCategoryList', { Type });

/* 取得常見問題分類下拉選項  */
export const GetQaCategorysList = (Type = 99) => baseAxios.post('/Base/GetQaCategorysList', { Type });

/* 取折扣類型下拉選項  */
export const GetDiscountType = (Type = 99) => baseAxios.post('/Base/GetDiscountType', { Type });

/* 取得訂單狀態下拉選單  */
export const GetOrderStatus = (Type = 99) => baseAxios.post('/Base/GetOrderStatus', { Type });

/* 取得訂單付款模式下拉選項  */
export const GetOrderPayMode = (Type = 99) => baseAxios.post('/Base/GetOrderPayMode', { Type });

/* 取得訂單付款狀態下拉選項  */
export const GetOrderPayStatus = (Type = 99) => baseAxios.post('/Base/GetOrderPayStatus', { Type });

/* 取得會員通知類型下拉選項  */
export const GetMemberNoticeMode = (Type = 99) => baseAxios.post('/Base/GetMemberNoticeMode', { Type });

/* 取通知狀態下拉選項  */
export const GetMemberNoticeStatus = (Type = 99) => baseAxios.post('/Base/GetMemberNoticeStatus', { Type });

/* 取得(審核用)訂單狀態下拉選項  */
export const GetOrderStatusByReview = (Type = 99) => baseAxios.post('/Base/GetOrderStatusByReview', { Type });

/* 取得套裝課程下拉選項 by 模糊名稱搜尋  */
export const GetBundleClassCourseList = function GetBundleClassCourseList(Name, Type = 99) {
  const str = { name: Name, type: Type };
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('/Base/GetBundleClassCourseList', str);
};

/* 取得會員信箱下拉選項 by 模糊搜尋  */
export const GetMemberIdByEmailList = function GetMemberIdByEmailList(Name, Type = 99) {
  const str = { name: Name, type: Type };
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('/Base/GetMemberIdByEmailList', str);
};

export const DownloadFileBase64 = (path) => baseAxios.post('/Upload/DownloadFileBase64', { path });
export const FrontEndDownloadFileBase64 = (path) => baseAxios.post('/FrontEnd/Upload/DownloadFileBase64', { path });

/*  發送驗證碼(通用) */
export const SendVerification = (data) => baseAxios.post('/FrontEnd/Verification/MemberSendVerification', data);

/* 取得意見標題下拉選單  */
export const GetFeedbackTitle = (Type = 99) => baseAxios.post('/Base/GetFeedbackTitle', { Type });

/* 取得考試去題庫下拉選單  */
export const GetPracticeCategorye = (data) => baseAxios.post('/Base/GetPracticeCategorye', data);
