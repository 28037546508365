<template>
  <!-- 主要內容 -->
  <section class="member pb-8" v-if="FeedBackData.info.id !== 0">
    <section class="container pb-8">
      <div class="cs-table" data-style="1">
        <div class="border-shadow mb-6">
          <h3
            class="table__title bg-info text-dark"
            :data-date="FeedBackData.info.feedBackDate | toTaipei | moment('YYYY-MM-DD HH:mm')"
          >
            <div class="seal" v-if="FeedBackData.info.status === 1">
              <div class="seal-son">
                <span>{{ FeedBackData.info.statusName }}</span>
              </div>
            </div>
            {{ FeedBackData.info.feedBackTitle }}
          </h3>
          <div class="table__body bg-light edit">
            <p>
              {{ FeedBackData.info.feedBackContent }}
            </p>
            <div class="text-right">
              <a
                v-if="FeedBackData.info.feedbackFilePath !== ''"
                class="btn btn-hover-primary text-white"
                href="#"
                @click.prevent="DowloadFile(FeedBackData.info.feedbackFilePath)"
                >檔案下載</a
              >
            </div>
          </div>
        </div>
      </div>
      <h2 class="cs-title my-3 my-md-6" data-style="1">
        <span data-title="問題回覆"></span>
      </h2>
      <div class="col-12 col-md-12 col-lg-12  p-0 m-0">
        <div class="tab-content">
          <ul class="cs-side" data-style="4">
            <template v-for="(item, index) in FeedBackData.replys">
              <li class="side__item" :key="item.replyId">
                <div class="side__title fa " :class="{ userfeback: item.feedbackType === 1 }">
                  <div class="row col-12 m-0 p-0">
                    <div class="col-2 col-md-2 col-lg-2 text-left">#{{ index + 1 }}</div>
                    <div class="col-10 col-md-10 col-lg-10 text-right">
                      {{ item.createDate | toTaipei | moment('YYYY-MM-DD HH:mm') }}
                    </div>
                  </div>
                </div>
                <div class="side__content" :class="{ userfeback: item.feedbackType === 1 }">
                  <div
                    style="white-space: pre-wrap;max-height: 500px; overflow: auto;"
                    class="py-3"
                  >
                    {{ item.feedBackContent }}
                  </div>
                  <div v-if="item.feedbackFilePath">
                    <button
                      type="button"
                      class="btn btn-hover-primary text-white btn-small float-right mb-2"
                      @click="DowloadFile(item.feedbackFilePath)"
                    >
                      <span class="small">檔案下載</span>
                    </button>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <el-form
          v-if="FeedBackData.info.status === 0"
          :model="FeedBackForm"
          ref="FeedBackForm"
          :rules="FeedBackRules"
          style="width: 100%;"
          label-position="top"
          class="cs-form border-gary mt-3"
          data-style="4"
          @submit.prevent.native="ReplyMemberFeedBack()"
        >
          <div class="row pl-2 pr-2 mb-2">
            <div class="col-12 col-md-2 col-lg-2 ">
              <label class="title necessary">內容</label>
            </div>
            <div class="col-12 col-md-10 col-lg-10 ">
              <el-form-item prop="feedBackContent">
                <textarea
                  v-model="FeedBackForm.feedBackContent"
                  class="form-control"
                  style="height:250px;"
                ></textarea>
                <div class="text--wordLimt">{{ FeedBackForm.feedBackContent.length }}/150</div>
              </el-form-item>
            </div>
          </div>
          <div class="row pl-2 pr-2 mb-2">
            <div class="col-12 col-md-2 col-lg-2 ">
              <label class="title ">檔案上傳</label>
            </div>
            <div class="col-12 col-md-10 col-lg-10 ">
              <input type="file" ref="file" id="file-uploader" @change="CheckFile" />
              <div slot="tip" class="el-upload__tip">
                只能上傳jpg/png文件，且不超過2MB
              </div>
            </div>
          </div>
          <div class="row pl-2 pr-2 mb-2">
            <div class="col-12 col-md-2 col-lg-2 ">
              <label class="title necessary">驗證碼</label>
            </div>
            <el-form-item prop="captchaCode" class="col-12 col-md-5">
              <el-input
                :class="`el-input__input`"
                placeholder="驗證碼"
                type="text"
                id="captchaCode"
                v-model="FeedBackForm.captchaCode"
              />
            </el-form-item>
            <div class="col-12 col-md-4">
              <img
                class="img-verify"
                :src="`data:image/png;base64,${CodeImg}`"
                @click="GetCaptchaCode"
                alt="驗證碼"
              />
            </div>
          </div>
          <div class="footer">
            <div></div>
            <div class="text-right">
              <button
                type="button"
                class="btn--gray px-3 py-1 py-md-1"
                @click="ReplyMemberFeedBack"
              >
                送出
              </button>
            </div>
          </div>
        </el-form>
      </div>
    </section>
  </section>
</template>

<script>
import { GetMemberFeedBackById, ReplyMemberFeedBack } from '@/api/FeedBackApi';
import { GetCaptchaCode, GetCaptchaBase64 } from '@/api/CaptchaApi';
import { FrontEndDownloadFileBase64 } from '@/api/Base/BaseApi';
import { convertBase64AndDownload } from '@/lib/File';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      FeedBackData: {
        info: {
          id: 0,
          status: 0,
          statusName: '',
          feedbackOwner: 0,
          feedbackOwnerName: '',
          feedBackTitle: '',
          feedBackContent: '',
          memberName: '',
          memberMail: '',
          feedBackDate: '',
          feedbackLastDate: '',
          feedbackFilePath: '',
        },
        replys: [],
      },
      FeedBackForm: {
        id: '',
        feedBackContent: '',
        iploadType: 11,
        code: '',
        captchaCode: '',
      },
      FeedBackRules: {
        feedBackContent: [
          { required: true, message: '請輸入內容', trigger: 'blur' },
          { max: 150, message: '內容不能超過150個字' },
        ],
        captchaCode: [{ required: true, message: '請輸入驗證碼', trigger: 'blur' }],
      },
      CodeImg: '',
    };
  },
  mounted() {
    this.GetData();
    this.GetCaptchaCode();
  },
  methods: {
    GetData() {
      const { id } = this.$route.params;
      GetMemberFeedBackById(id).then((response) => {
        if (response.data.statusCode === 0) {
          this.FeedBackData = response.data.data;
          this.$emit('update:currentPage', {
            name: '',
            title: this.FeedBackData.info.feedBackTitle,
            path: '',
          });
        }
      });
    },
    DowloadFile(file) {
      FrontEndDownloadFileBase64(file).then((response) => {
        if (response.data.statusCode === 0) {
          this.StatusOption = response.data.data;
          this.$message({
            type: 'success',
            message: '請稍後，檔案下載即將開始',
          });
          convertBase64AndDownload(
            response.data.data.base64,
            response.data.data.fileName,
            'application/octet-stream',
          );
        }
      });
    },
    GetCaptchaCode() {
      // 取得驗證碼
      GetCaptchaCode().then((response) => {
        if (response.data.statusCode === 0) {
          this.FeedBackForm.code = response.data.data.code;
          GetCaptchaBase64(this.FeedBackForm.code).then((res) => {
            this.CodeImg = res.data;
          });
        }
      });
    },
    CheckFile() {
      const file = this.$refs.file.files[0];
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
        this.$message.error('上傳圖片只能是 JPG/PNG 格式!');
        this.$refs.file.value = '';
      }
      if (!isLt2M) {
        this.$message.error('上傳圖片大小不能超過 2MB!');
        this.$refs.file.value = '';
      }
    },
    ReplyMemberFeedBack() {
      this.$refs.FeedBackForm.validate((valid) => {
        if (valid) {
          const { id } = this.$route.params;
          this.FeedBackForm.id = id;
          const uploadedFile = this.$refs.file.files[0];

          const formData = new FormData();
          const objKey = Object.keys(this.FeedBackForm);
          objKey.forEach((item) => {
            formData.append(item, this.FeedBackForm[item]);
          });
          if (uploadedFile) formData.append('files', uploadedFile);
          ReplyMemberFeedBack(formData).then((response) => {
            if (response.data.statusCode === 0) {
              this.$message({
                showClose: true,
                type: 'success',
                message: '回覆成功',
              });
              this.FeedBackForm = {
                id: 0,
                feedBackContent: '',
                iploadType: 11,
                code: '',
                captchaCode: '',
              };
              this.$refs.file.value = '';
              setTimeout(() => {
                this.GetData();
              }, 1000);
            }
            this.GetCaptchaCode();
          });
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text--wordLimt {
  font-size: 12px;
  line-height: 0;
  position: absolute;
  bottom: 11px;
  right: 3px;
}

.table__title {
  position: relative;
}
.ul-li-block {
  display: block;
}
.seal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border: solid 2px #28a745;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.8);

  @media (min-width: 992px) {
    float: left;
    transform: translate(-14px, -14px);
  }
  @media (max-width: 991px) {
    position: absolute;
    right: -10px;
    top: -35px;
  }
}
.seal-son {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  border: solid 2px #28a745;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  transform: rotate(-15deg);

  span {
    font-size: 15px;
    font-weight: 700;
    color: #28a745;
  }
}
</style>
